<template>
  <div id="comercio-highlights-comp">
    <div class="section-grid">
      <div class="highlight">
        <img src="/static/icons/gear-icon.svg" alt="" />
        <h3>Productos personalizables</h3>
        <div class="description">
          Fabricamos los productos a tu gusto y necesidad, para todos las marcas
          y modelos
        </div>
      </div>
      <div class="highlight">
        <img src="/static/icons/garage-icon.svg" alt="" />
        <h3>Colocaciones en el taller</h3>
        <div class="description">
          Sacá tu turno, visitanos en el taller y te colocamos los productos
          nosotros
        </div>
      </div>
      <div class="highlight">
        <img src="/static/icons/wallet-icon.svg" alt="" />
        <h3>Variedad de medios de pago</h3>
        <div class="description">
          Elegi la forma de pago que mas te convenga. Efectivo, debito, credito
          o transferencia bancaria
        </div>
      </div>
      <div class="highlight">
        <img src="/static/icons/shield-icon.svg" alt="" />
        <h3>Garantia asegurada</h3>
        <div class="description">
          Tenés 1 año de garantia asegurada para todos nuestros productos
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
#comercio-highlights-comp {
  @media (max-width: 767px) {
    padding-top: 3rem;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
  .section-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 4rem;

    @media (max-width: 767px) {
      gap: 2rem;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  .highlight {
    @media (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
    img {
      height: 90px;
      width: auto;
      margin-bottom: 1.5rem;

      @media (max-width: 767px) {
        height: 60px;
      }
      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1200px) {
      }
    }
    h3 {
      margin-bottom: 0.5rem;

      @media (max-width: 767px) {
        text-align: center;
        font-size: 18px;
        line-height: 20px;
      }
      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1200px) {
      }
    }
    .description {
      font-size: 18px;
      color: var(--color__tertiary);
      @media (max-width: 767px) {
        text-align: center;
        font-size: 13px;
        line-height: 18px;
      }
      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1200px) {
      }
    }
  }
}
</style>
