import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import '@/assets/scss/swiper/swiper.css';

Vue.use(VueAwesomeSwiper);

//variables
Vue.prototype.$phone = '011 3315-7260';
Vue.prototype.$instagram = 'bumperprotect';
Vue.prototype.$facebook = 'protecciontransparente';
Vue.prototype.$direccion = 'Nogoyá 4462, C1417 CABA, Buenos Aires';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
