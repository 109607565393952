<template>
  <div id="product-card" class="product-card" v-if="item">
    <div
      class="p-card-header"
      :style="{ 'background-image': 'url(' + item.img + ')' }"
    ></div>
    <div class="p-card-body">
      <div class="title">
        <router-link :to="item.url" tag="h4" class="fw-bold">{{
          item.name
        }}</router-link>
      </div>
      <!-- <div class="description">
        {{ item.subtitle }}
      </div> -->
    </div>
    <div class="p-card-footer">
      <router-link :to="item.url"
        ><i class="bi bi-arrow-right-circle-fill"></i
      ></router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
<style scoped lang="scss">
#product-card {
}
</style>
