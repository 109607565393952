<template>
  <nav id="header-mobile-menu">
    <!-- <div class="user-info">
      <div class="user-data" v-if="isLoggedIn">
        <div class="user-name">{{ getUser.name }}</div>
        <div class="user-email">{{ getUser.email }}</div>
        <div class="logout"><span>Salir</span></div>
      </div>
      <div class="login-options" v-if="!isLoggedIn">
        <div class="welcome">Bienvenido</div>
        <div class="login-buttons">
          <router-link tag="div" :to="{ name: 'login' }" class="login-btn"
            >Ingresar</router-link
          >
          <router-link tag="div" :to="{ name: 'register' }" class="register-btn"
            >Registrate</router-link
          >
        </div>
      </div>
    </div> -->
    <ul>
      <li>
        <router-link :to="{ name: 'home' }"
          ><i class="bi bi-house"></i>Home</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'home', query: { section: 'productos' } }"
          ><i class="bi bi-star"></i>Productos</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'home', query: { section: 'aboutus' } }"
          ><i class="bi bi-shop-window"></i>Sobre Nosotros</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'gallery' }"
          ><i class="bi bi-images"></i>Galeria</router-link
        >
      </li>
      <!-- <li v-if="isLoggedIn">
        <router-link :to="{ name: 'dashboard' }"
          ><i class="fas fa-bell"></i>Notificaciones</router-link
        >
      </li>
      <li v-if="isLoggedIn">
        <router-link :to="{ name: 'dashboard' }"
          ><i class="fas fa-shopping-bag"></i>Carrito</router-link
        >
      </li>
      <li v-if="isLoggedIn">
        <router-link :to="{ name: 'dashboard' }"
          ><i class="fas fa-heart"></i>Favoritos</router-link
        >
      </li>
      <li v-if="isLoggedIn">
        <router-link :to="{ name: 'profile' }"
          ><i class="fas fa-user"></i>Mi Cuenta</router-link
        >
      </li> -->
      <!-- <li>
        <a><i class="bi bi-question-lg"></i>Ayuda</a>
      </li> -->
    </ul>
    <!-- <ul>
      <li>
        <a><i class="bi bi-bookmark"></i>Categorias</a>
        <ul class="categorias-wrap" v-if="categorias">
          <router-link
            tag="li"
            v-for="categoria in categorias"
            :key="categoria.id"
            :to="categoria.url"
          >
            <i class="bi bi-chevron-right"></i>
            <div class="cat-name">
              {{ categoria.name }}
            </div>
          </router-link>
        </ul>
      </li> -->
    <!-- <li>
        <a><i class="bi bi-images"></i>Galeria</a>
      </li>
      <li>
        <a><i class="bi bi-info-lg"></i>Sobre Nosotros</a>
      </li> -->
    <!-- </ul> -->
  </nav>
</template>
<script>
import Categorias from '@/data/listings/categorias.json';

export default {
  data() {
    return {
      categorias: Categorias.data,
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
#header-mobile-menu {
  position: fixed;
  top: 50px;
  left: 0;
  height: 100vh;
  width: 245px;
  background-color: var(--color__card);
  z-index: 999;
  transform: translateX(-280px);
  transition: transform 0.5s;
  overflow: scroll;
  border-right: 1px solid var(--color__border);

  &.menu-open {
    transform: translateX(0);
  }

  .user-info {
    display: flex;
    padding: 1.5rem 2rem;

    .user-data {
      font-family: 'Lato', sans-serif !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: $darkgrey;
      font-size: 14px;

      .user-name {
        font-weight: 700 !important;
        width: 100%;
      }
      .user-email {
        font-weight: 400 !important;
        width: 100%;
      }
      .logout {
        margin-top: 0.25rem;

        span {
          color: $primary;
          cursor: pointer;
        }
      }
    }
    .login-options {
      font-family: 'Lato', sans-serif !important;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: $darkgrey;
      font-size: 14px;

      .welcome {
        font-weight: 700 !important;
        width: 100%;
        margin-bottom: 0.5rem;
        font-size: 16px;
      }
      .login-buttons {
        width: 100%;
        display: flex;

        .login-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: $primary;
          color: $secondary;
          width: 100px;
          height: 40px;
          margin-right: 0.5rem;
          padding-bottom: 0.1rem;
          font-weight: 600;
        }
        .register-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: $lightgrey;
          color: $secondary;
          width: 100px;
          height: 40px;
          padding-bottom: 0.1rem;
          font-weight: 600;
        }
      }
    }
  }

  ul.categorias-wrap {
    border-bottom: none;
    li {
      font-size: 13px;
      padding: 0.7rem 1rem 0.7rem 0.25rem;
      color: $darkgrey;
      font-weight: 600;
      cursor: pointer;
      font-family: 'Lato', sans-serif !important;
      text-decoration: none;
      display: flex;

      .cat-name {
        padding-left: 0.7rem;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0.5rem 0;
    padding-top: 0.5em;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    li {
      padding: 0.85rem 1rem;
      position: relative;
      width: 100%;
      a {
        color: var(--color__tertiary);
        font-weight: 600;
        cursor: pointer;
        transition: all ease-in-out 0.15s;
        // font-family: "Lato", sans-serif !important;
        font-weight: 400 !important;
        font-size: 16px;
        text-decoration: none;

        i {
          margin-right: 1rem;
          font-size: 19px;
        }
      }
    }
  }
}
</style>
