<template>
  <div id="article-data" v-if="item">
    <div class="article-data-wrap">
      <h3 class="mb-4">{{ item.name }}</h3>
      <h4 class="mb-4">
        {{ item.subtitle }}
      </h4>
      <!-- <h3 class="mb-4">
        <div class="price">
          <span class="texto">Desde</span><span class="signo">$</span>
          <div class="numero">
            {{ Intl.NumberFormat('de-DE').format(item.price) }}
          </div>
        </div>
      </h3> -->
      <a :href="item.wplink" class="mos-btn-md">
        <i class="bi bi-whatsapp me-2"></i>Pedir por WhatsApp
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item'],
};
</script>
<style scoped lang="scss">
#article-data {
  .article-data-wrap {
    width: 100%;
    background-color: var(--color__card);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 1.5rem;
  }

  a.mos-btn-md:hover {
    color: unset;
  }

  h4 {
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }
}
</style>
