<template>
  <div id="home-page">
    <HeroComp :isdark="isdark" />
    <ProductsComp />
    <AboutusComp />
  </div>
</template>
<script>
import HeroComp from './components/HeroComp.vue';
import ProductsComp from './components/ProductsComp.vue';
import AboutusComp from './components/AboutusComp.vue';

export default {
  props: ['isdark'],
  components: {
    HeroComp,
    ProductsComp,
    AboutusComp,
  },
  mounted() {
    if (this.$route.query.section) {
      this.scrollTo(this.$route.query.section);
    } else {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style scoped lang="scss">
#home-page {
  background-color: var(--color__secondary);
}
</style>
