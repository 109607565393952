<template>
  <div id="header-mobile">
    <nav class="top-nav">
      <div class="container">
        <div class="menu-btn">
          <a @click="menuToggle()">
            <i class="bi bi-list"></i>
          </a>
        </div>
        <div class="brand">
          <router-link
            tag="img"
            :to="{ name: 'home' }"
            src="/static/logos/com-logo-mini.svg"
            alt=""
          ></router-link>
        </div>
      </div>
    </nav>
    <HeaderMobileMenu :class="showMenu ? 'menu-open' : ''" />
    <div class="menu-overlay" :class="showMenu ? 'overlay-open' : ''"></div>
  </div>
</template>
<script>
import HeaderMobileMenu from './HeaderMobileMenu.vue';

export default {
  data() {
    return {
      showMenu: false,
    };
  },
  props: ['darkmode'],
  components: {
    HeaderMobileMenu,
  },
  methods: {
    switchtheme(color) {
      this.$emit('themechange', color);
    },
    menuToggle() {
      this.showMenu = !this.showMenu;
      this.showSearchbar = false;
    },
  },
};
</script>
<style scoped lang="scss">
#header-mobile {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color__card);
  border-bottom: 1px solid var(--color__border);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 0.5rem 0;
  z-index: 9999;

  .top-nav {
    > .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
    .menu-btn {
      height: 35px;
      width: 35px;

      a {
        height: 100%;
        width: 100%;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        i {
          font-size: 25px;
          color: var(--color__tertiary);
        }
      }
    }
    .brand {
      img {
        height: 35px;
        width: auto;
        cursor: pointer;
      }
    }
    .right-clickers {
      svg {
        width: 50px;
        height: 30px;
      }
    }
  }
  .menu-overlay {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.overlay-open {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
