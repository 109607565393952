<template>
  <div id="main" class="theme" :class="darkmode == true ? 'theme--dark' : ''">
    <Header v-if="!isMobile" @themechange="switchtheme" :darkmode="darkmode" />
    <HeaderMobile v-else @themechange="switchtheme" :darkmode="darkmode" />
    <div class="content">
      <router-view :key="$route.fullPath" :isdark="darkmode"></router-view>
    </div>
    <Footer :isdark="darkmode" />
  </div>
</template>
<script>
import mobileChecker from '@/mixins/mobileChecker';
import Header from 'Components/header/Header.vue';
import HeaderMobile from 'Components/header/headermobile/HeaderMobile.vue';
import Footer from 'Components/footer/Footer.vue';

export default {
  mixins: [mobileChecker],
  props: ['darkmode'],
  components: {
    Header,
    HeaderMobile,
    Footer,
  },
  methods: {
    switchtheme(color) {
      this.$emit('switchtheme', color);
    },
  },
};
</script>
<style scoped lang="scss">
#main {
  .content {
    min-height: 100vh;
  }
}
</style>
