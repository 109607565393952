<template>
  <div id="socialmedia">
    <a
      class="social-media-item instagram"
      :href="'https://www.instagram.com/' + $instagram"
    >
      <i class="bi bi-instagram"></i>
    </a>
    <a
      class="social-media-item facebook"
      :href="'https://www.facebook.com/' + $facebook"
    >
      <i class="bi bi-facebook"></i>
    </a>
    <!-- <div class="social-media-item youtube">
      <i class="bi bi-youtube"></i>
    </div>
    <div class="social-media-item twitter">
      <i class="bi bi-twitter"></i>
    </div> -->
    <a href="https://wa.link/820cbp" class="social-media-item whatsapp">
      <i class="bi bi-whatsapp"></i>
    </a>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
#socialmedia {
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    justify-content: flex-end;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }

  .social-media-item {
    height: 35px;
    width: 35px;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-left: 0.75rem;
    }
    @media (min-width: 768px) {
      z-index: 999;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }

    &:hover {
      background-color: $tertiary !important;
    }

    &.twitter {
      background-color: #28a9e2;
    }

    &.instagram {
      background-color: #567d9c;
    }

    &.facebook {
      background-color: #4064ac;
    }

    &.youtube {
      background-color: #f60002;
    }

    &.whatsapp {
      background-color: #48c456;
    }

    i {
      font-size: 1rem;
      color: $white;
    }
  }
}
</style>
