<template>
  <div id="mediosdepago-footer">
    <div class="sec-title">Medios de Pago:</div>
    <div class="row">
      <div class="col-12 mediodepago">
        <i class="bi bi-cash"></i><span>Efectivo</span>
      </div>
      <div class="col-12 mediodepago">
        <i class="bi bi-credit-card"></i
        ><span>Débito, Crédito o Transferencia Bancaria</span>
      </div>
    </div>
    <h5 class="mt-md-3">
      Envíos a todo el país por vía cargo, Andreani o Correo argentino
    </h5>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
#mediosdepago-footer {
  padding-top: 1rem;

  @media (max-width: 767px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }

  .sec-title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 14px;
  }

  .mediodepago {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    i {
      color: var(--color__altertext);
      margin-right: 0.75rem;
      font-size: 1.1em;
    }
    span {
      color: var(--color__tertiary);
      font-size: 13px;
    }
  }

  h5 {
    color: var(--color__tertiary);
    font-size: 12px;
  }
}
</style>
