<template>
  <div id="article-page">
    <div class="container" v-if="producto_id">
      <div class="row">
        <ArticleGalleryComp class="col-12 col-md-6" :items="GaleriaItem" />
        <ArticleDataComp
          class="col-12 col-md-6 mt-4 mt-md-0"
          :item="ArticuloItem"
        />
        <OtrosProductosComp class="col-12" :items="OtrosArticulos" />
      </div>
    </div>
  </div>
</template>
<script>
import ArticleGalleryComp from './components/ArticleGalleryComp.vue';
import ArticleDataComp from './components/ArticleDataComp.vue';
import OtrosProductosComp from 'Components/global/articulos/OtrosProductosComp.vue';
import Categorias from '@/data/listings/categorias.json';
import Galleries from '@/data/listings/gallery.json';

export default {
  data() {
    return {
      articulos: Categorias.data,
      galerias: Galleries.data,
      producto_id: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.producto_id = parseInt(this.$route.params.nombre.split('_')[0], 10);
  },
  computed: {
    ArticuloItem: function () {
      return this.articulos.find((x) => x.id === this.producto_id);
    },
    GaleriaItem: function () {
      return this.galerias.filter((x) => x.category === this.producto_id);
    },
    OtrosArticulos: function () {
      return this.articulos.filter((x) => x.id !== this.producto_id);
    },
  },
  components: {
    ArticleGalleryComp,
    ArticleDataComp,
    OtrosProductosComp,
  },
};
</script>
<style scoped lang="scss">
#article-page {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: var(--color__secondary);

  @media (max-width: 767px) {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
}
</style>
