<template>
  <div id="comdata-footer">
    <div class="row">
      <div class="col-6 col-md-12">
        <div class="brand">
          <img src="/static/logos/com-logo-big-black.png" alt="" />
        </div>
      </div>
      <div class="col-6 col-md-12" v-if="isMobile">
        <SocialmediaComponent />
      </div>
      <div class="col-12 col-md-12">
        <ul>
          <li>
            <i class="bi bi-geo-alt"></i>
            <span>{{ $direccion }}</span>
          </li>
          <li>
            <i class="bi bi-telephone"></i><span>{{ $phone }}</span>
          </li>
          <!-- <li>
        <i class="bi bi-envelope"></i><span>bumperprotector@gmail.com</span>
      </li> -->
        </ul>
      </div>
      <div class="col-6 col-md-12" v-if="!isMobile">
        <SocialmediaComponent />
      </div>
    </div>
  </div>
</template>
<script>
import SocialmediaComponent from 'Components/global/SocialmediaComponent';
import mobileChecker from '@/mixins/mobileChecker';

export default {
  props: ['isdark'],
  mixins: [mobileChecker],
  components: {
    SocialmediaComponent,
  },
};
</script>
<style scoped lang="scss">
#comdata-footer {
  ul {
    list-style: none;
    padding: 20px 0 5px 0;
    margin-bottom: 0.5rem;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }

    li {
      margin-bottom: 0.6rem;
      display: flex;
      align-items: center;

      i {
        margin-right: 0.75rem;
        margin-top: 1px;
        color: var(--color__altertext);
        font-size: 1.1em;
      }
      span {
        font-size: 13px;
      }
    }
  }
  .brand {
    img {
      height: 60px;
      width: auto;
    }
  }
}
</style>
