<template>
  <div id="article-gallery" v-if="items">
    <div class="swiper-wrap">
      <swiper
        class="swiper gallery-top"
        :options="swiperOptionTop"
        ref="swiperTop"
      >
        <swiper-slide class="slide" v-for="item in items" :key="item.id">
          <img :src="item.path" alt="" />
        </swiper-slide>
        <div
          class="swiper-button-next swiper-button-white"
          slot="button-next"
        ></div>
        <div
          class="swiper-button-prev swiper-button-white"
          slot="button-prev"
        ></div>
      </swiper>
      <!-- swiper2 Thumbs -->
      <swiper
        class="swiper gallery-thumbs"
        :options="swiperOptionThumbs"
        ref="swiperThumbs"
      >
        <swiper-slide class="slide" v-for="item in items" :key="item.id">
          <img :src="item.path" alt="" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
// import Galleries from "@/data/listings/gallery.json";

export default {
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
    };
  },
  props: ['items'],
  components: {
    Swiper,
    SwiperSlide,
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
};
</script>
<style scoped lang="scss">
#article-gallery {
  .swiper-wrap {
    height: 480px;
    width: 100%;
    background-color: var(--color__card);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .swiper {
    .swiper-slide {
      background-size: cover;
      background-position: center;
      position: relative;
      vertical-align: top;
      margin: 0;
      overflow: hidden;
      background-color: transparent;

      img {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        object-fit: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    .swiper-button-next {
      color: var(--color__primary);
      //   right: 0;
    }

    .swiper-button-prev {
      color: var(--color__primary);
      //   left: 0;
    }

    &.gallery-top {
      height: 80%;
      width: 100%;
      padding-bottom: 0.5rem;
    }
    &.gallery-thumbs {
      height: 20%;
      box-sizing: border-box;
      padding: 0;
    }
    &.gallery-thumbs .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }
    &.gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
  }
}
</style>
