<template>
  <div id="aboutus">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 section-aboutus">
          <h1>
            Hola, somos <br />
            BumperProtector
          </h1>
          <div class="description">
            Nos dedicamos a fabricar los mejores productos de protección para
            automóviles, producimos la materia prima de mejor calidad y la
            moldeamos para todas las marcas y modelos de autos existentes,
            usamos los mejores adhesivos 3M. Nos centramos en la atención
            personalizada de cada cliente, dependiendo de sus gustos y
            necesidades. Tenemos la maquinaria para hacer posible cualquier
            especificación. Si nos visitás en nuestro taller te vas a sentir
            cómodo y bien atendido, si no hablanos al WhatsApp y contanos que
            necesitas.
          </div>
          <h2>¿Donde estamos?</h2>
          <ul>
            <li>
              <i class="bi bi-geo-alt"></i>
              <span>{{ $direccion }}</span>
            </li>
            <li>
              <i class="bi bi-telephone"></i><span>{{ $phone }}</span>
            </li>
            <li class="mt-3">
              <SocialmediaComponent />
            </li>
          </ul>
          <!-- <img src="/static/publi/bumperweb-location3.jpg" alt="" /> -->
        </div>
        <div class="col-12 col-md-6">
          <ComercioHighlightsComp />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <TestimonialsComp />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ComercioHighlightsComp from 'Components/global/comercio/ComercioHighlightsComp.vue';
import SocialmediaComponent from 'Components/global/SocialmediaComponent.vue';
import TestimonialsComp from './TestimonialsComp.vue';
import mobileChecker from '@/mixins/mobileChecker';

export default {
  mixins: [mobileChecker],

  components: {
    ComercioHighlightsComp,
    SocialmediaComponent,
    TestimonialsComp,
  },
};
</script>
<style scoped lang="scss">
#aboutus {
  min-height: 100vh;
  background-color: transparent;
  padding: 6rem 0 0 0;
  position: relative;

  @media (max-width: 767px) {
    padding: 4.5rem 0 2rem 0;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }

  .section-aboutus {
    @media (max-width: 767px) {
    }
    @media (min-width: 768px) {
      padding-right: 4rem;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  h1 {
    color: var(--color__tertiary);
    margin-bottom: 2rem;

    @media (max-width: 767px) {
      text-align: center;
      margin-bottom: 1rem;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  .description {
    font-size: 18px;
    color: var(--color__tertiary);
    margin-bottom: 3rem;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  h2 {
    color: var(--color__tertiary);
    margin-bottom: 1rem;
    font-size: 24px;

    @media (max-width: 767px) {
      text-align: center;
      font-size: 17px;
      margin-bottom: 1rem;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  ul {
    list-style: none;
    padding: 0;

    @media (max-width: 767px) {
      margin-bottom: 0;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }

    li {
      margin-bottom: 0.6rem;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        justify-content: center;
      }
      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1200px) {
      }

      i {
        margin-right: 0.75rem;
        margin-top: 1px;
        color: var(--color__tertiary);
        font-size: 1.1em;
      }
      span {
        font-size: 14px;
        color: var(--color__tertiary);
      }
    }
  }

  img {
    width: 100%;
  }
}
</style>
