<template>
  <div id="sitelinks-footer">
    <div class="row">
      <div class="col-12 col-md-6 site-info">
        <div class="sec-title">Informacion</div>
        <ul>
          <!-- <li><a>Login</a></li>
          <li><a>Registro</a></li> -->
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>
            <router-link :to="{ name: 'home', query: { section: 'productos' } }"
              >Productos</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'home', query: { section: 'aboutus' } }"
              >Sobre Nosotros</router-link
            >
          </li>
          <!-- <li><a>Categorias</a></li> -->
          <li><router-link :to="{ name: 'gallery' }">Galeria</router-link></li>
        </ul>
      </div>
      <!-- <div class="col-6 col-md-6 site-us"> -->
      <!-- <div class="sec-title">Quienes Somos</div>
        <ul>
          <li><a>Sobre Nosotros</a></li>
          <li><a>Contactos</a></li>
          <li><a>Como Comprar</a></li>
          <li><a>Ayuda</a></li>
        </ul> -->
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
#sitelinks-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 14px;
  .sec-title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 0.65rem;

      a {
        cursor: pointer;
        color: var(--color__altertext);
        transition: all 0.15s ease-in-out;
        text-decoration: none;

        &:hover {
          color: var(--color__primary);
        }
      }
    }
  }

  .site-info {
    > ul {
      padding: 0;
    }
  }
  .site-us {
    > ul {
      padding: 0 0 0 15px;
      li {
        &::before {
          content: '\2023';
          color: var(--color__primary);
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
}
</style>
