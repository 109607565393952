<template>
  <div id="productos">
    <div class="container" v-if="items">
      <!-- <h2 class="text-center mb-4">Nuestos Productos</h2> -->
      <section class="main-box">
        <ProductCardComp v-for="item in items" :key="item.id" :item="item" />
      </section>
    </div>
    <div class="mos-btn-lg" @click="scrollTo('aboutus')">
      <span>Sobre Nosotros</span>
      <img src="/static/icons/arrow-down.svg" alt="" />
    </div>
  </div>
</template>
<script>
import Productos from '@/data/listings/categorias.json';
import ProductCardComp from 'Components/global/cards/ProductCardComp.vue';

export default {
  data() {
    return {
      items: Productos.data,
    };
  },
  components: {
    ProductCardComp,
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style scoped lang="scss">
#productos {
  min-height: 100vh;
  background-color: transparent;
  padding: 6rem 0 9rem 0;
  position: relative;

  @media (max-width: 767px) {
    padding: 4.5rem 0 9rem 0;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }

  .main-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    width: 100%;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    text-align: center;
    height: fit-content;

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0.75rem;
      grid-row-gap: 0.75rem;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  .mos-btn-lg {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>
