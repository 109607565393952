<template>
  <div id="footer">
    <div class="container">
      <div class="top-section">
        <div class="row">
          <ComdataComponent :isdark="isdark" class="col-12 col-md-4" />
          <SitelinksComponent class="col-6 col-md-4" />
          <MediosdepagoComp class="col-6 col-md-4" />
          <!-- <NewsletterComponent class="col-md-3" /> -->
        </div>
      </div>
      <div class="bottom-section">
        <div class="copyrights">
          © 2023 BumperProtector. All Rights Reserved.
        </div>
        <div class="yivo-link">
          <span>Website made in </span>
          <a href="https://yivocode.com" target="_blank">
            <img src="/static/logos/yivo-logo.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SitelinksComponent from './SitelinksComponent';
import ComdataComponent from './ComdataComponent.vue';
import MediosdepagoComp from './MediosdepagoComp.vue';
// import NewsletterComponent from "./NewsletterComponent";

export default {
  props: ['isdark'],
  components: {
    SitelinksComponent,
    ComdataComponent,
    MediosdepagoComp,
    // NewsletterComponent,
  },
};
</script>
<style scoped lang="scss">
#footer {
  width: 100%;
  color: var(--color__tertiary);
  background-color: var(--color__card);
  border-top: 1px solid var(--color__border);

  .top-section {
    padding-top: 40px;

    @media (max-width: 767px) {
      padding-top: 20px;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  .bottom-section {
    padding: 2rem 0 1rem 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: 767px) {
      padding: 1rem 0;
      flex-direction: column-reverse;
      align-items: center;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }

    .copyrights {
      font-size: 12px;
      margin-right: 0.5rem;

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }

    .yivo-link {
      display: flex;
      align-items: flex-end;

      @media (max-width: 767px) {
        margin-bottom: 0.4rem;
      }

      span {
        font-size: 12px;
        font-weight: 600;
        margin-right: 0.5rem;
      }
      a {
        height: fit-content;
        display: flex;
        padding-bottom: 3px;
      }
      img {
        height: 11px;
        width: auto;
      }
    }
  }
}
</style>
