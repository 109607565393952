<template>
  <div id="testimonials-home" v-if="testimonios">
    <h2 class="text-center mb-4">Testimonios de nuestros clientes</h2>
    <div class="row">
      <div
        class="col-12 col-md-3 article-column"
        v-for="item in testimonios"
        :key="item.id"
        :class="[
          { arl: item.id === 1 || item.id === 3 },
          { arr: item.id === 2 || item.id === 4 },
        ]"
      >
        <article class="testimonials-card mb-3 mb-md-0">
          <div class="w-100 icon-cont"><i class="bi bi-quote"></i></div>
          <div class="texto">
            {{ item.text }}
          </div>
          <div class="r-name">{{ item.name }}</div>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
import Testimonios from '@/data/listings/testimonios.json';

export default {
  data() {
    return {
      testimonios: Testimonios.data,
    };
  },
};
</script>
<style scoped lang="scss">
#testimonials-home {
  padding: 4rem 0;

  @media (max-width: 767px) {
    padding: 3rem 0 0 0;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }

  h2 {
    font-size: 24px;

    @media (max-width: 767px) {
      font-size: 17px;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }

  .article-column.arl {
    @media (max-width: 767px) {
      padding-right: 4rem;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }
  .article-column.arr {
    @media (max-width: 767px) {
      padding-left: 4rem;
    }
    @media (min-width: 768px) {
      padding-top: 4rem;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }
}
</style>
