<template>
  <div id="header">
    <div class="container">
      <div class="brand">
        <router-link
          tag="img"
          :to="{ name: 'home' }"
          src="/static/logos/com-logo-mini.svg"
          alt=""
        ></router-link>
      </div>
      <div class="menu">
        <HeaderMenu />
      </div>
      <!-- <div class="right-clickers">
        <DarkModeToggle :darkmode="darkmode" @themechange="switchtheme" />
      </div> -->
    </div>
  </div>
</template>
<script>
import HeaderMenu from './HeaderMenu.vue';

export default {
  props: ['darkmode'],
  components: {
    HeaderMenu,
  },
  methods: {
    switchtheme(color) {
      this.$emit('themechange', color);
    },
  },
};
</script>
<style scoped lang="scss">
#header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color__card);
  border-bottom: 1px solid var(--color__border);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  padding: 0.5rem 0;
  z-index: 9999;

  > .container {
    display: flex;
  }

  .brand {
    padding-right: 1rem;
    img {
      height: 50px;
      width: auto;
      cursor: pointer;
    }
  }

  .menu {
    flex: 1;
  }

  .right-clickers {
    svg {
      width: 90px;
      height: 50px;
    }
  }
}
</style>
