import Vue from 'vue';
import VueRouter from 'vue-router';

//pages
import Layout from '../pages/front/Layout.vue';
import Home from '../pages/front/home/Home.vue';
import Gallery from '../pages/front/gallery/Gallery.vue';
import Article from '../pages/front/article/Article.vue';

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        name: 'home',
      },
      {
        path: 'galeria',
        component: Gallery,
        name: 'gallery',
      },
      {
        path: 'articulo/:nombre',
        component: Article,
        name: 'article',
      },
    ],
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
