<template>
  <ul id="header-menu">
    <li><router-link :to="{ name: 'home' }">Home</router-link></li>
    <li>
      <router-link :to="{ name: 'home', query: { section: 'productos' } }"
        >Productos</router-link
      >
    </li>
    <li>
      <router-link :to="{ name: 'home', query: { section: 'aboutus' } }"
        >Sobre Nosotros</router-link
      >
    </li>
    <li><router-link :to="{ name: 'gallery' }">Galeria</router-link></li>
  </ul>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
#header-menu {
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;

  li {
    padding: 0 1rem;

    a {
      cursor: pointer;
      color: var(--color__tertiary);
      font-family: 'Titillium', sans-serif;
      transition: 0.1s all ease-in-out;
      text-decoration: none;

      &:hover {
        color: var(--color__primary);
      }
    }
  }
}
</style>
