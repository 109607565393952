<template>
  <div id="otros-productos-comp" class="w-100" v-if="items">
    <h2 class="text-center mb-0">Otros productos</h2>
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="item in items" :key="item.id">
        <ProductCardComp :item="item" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
import ProductCardComp from '@/components/global/cards/ProductCardComp.vue';

export default {
  props: ['items'],
  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
          },
          770: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      },
    };
  },
  components: {
    ProductCardComp,
  },
};
</script>
<style scoped lang="scss">
#otros-productos-comp {
  padding-top: 3rem;

  h2 {
    font-size: 24px;
    color: var(--color__tertiary);
  }

  .swiper-container {
    padding: 2rem 0;
  }
  .swiper-pagination-bullets {
    bottom: 4px;
  }
}
</style>
