<template>
  <div id="gallery-page">
    <div class="container">
      <h3 class="fw-normal mb-3">Galeria de imagenes</h3>
      <GalleryFiltersComp @changeCategory="filterCategory" />
      <div class="gallery-wrapper">
        <article v-for="item in GalleryItems" :key="item.id">
          <div
            class="gallery"
            :style="{ 'background-image': 'url(' + item.path + ')' }"
          >
            <div class="overlay"></div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
import GalleryFiltersComp from './components/GalleryFiltersComp.vue';
import Galleries from '@/data/listings/gallery.json';

export default {
  data() {
    return {
      items: Galleries.data,
      categorySelected: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    GalleryItems: function () {
      if (this.categorySelected) {
        return this.items.filter((x) => x.category === this.categorySelected);
      } else {
        return this.items;
      }
    },
  },
  methods: {
    filterCategory(category) {
      this.categorySelected = category;
    },
  },
  components: {
    GalleryFiltersComp,
  },
};
</script>
<style scoped lang="scss">
#gallery-page {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: var(--color__secondary);

  @media (max-width: 767px) {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }

  .gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }

    article {
      height: 15.8rem;
      overflow: hidden;
      position: relative;

      @media (max-width: 767px) {
        height: 12.2em;
      }
      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1200px) {
      }
      @media (min-width: 1400px) {
        height: 18.5rem;
      }

      .gallery {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        transition: 0.4s all ease-in-out;

        &:hover {
          @media (max-width: 767px) {
          }
          @media (min-width: 768px) {
            transform: scale(1.1);
          }
          @media (min-width: 992px) {
          }
          @media (min-width: 1200px) {
          }

          .overlay {
            visibility: visible;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);

            h5 {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        .overlay {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(0, 0, 0, 0);
          height: 77%;
          width: 77%;
          visibility: hidden;
          transition: 0.4s all ease-in-out;
          padding: 1.5em 2em;
          display: flex;
          align-items: flex-end;

          h5 {
            visibility: hidden;
            opacity: 0;
            transition: 0.5s all;
          }
        }
      }
    }
  }
}
</style>
