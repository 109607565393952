<template>
  <div id="hero-home">
    <section class="main-box">
      <div class="com-brand">
        <img src="/static/logos/com-logo-mini.svg" alt="" />
      </div>
      <div class="com-name">
        <img src="/static/logos/com-logo-text-darkmode.png" alt="" />
      </div>
      <div class="com-slogan">
        <h3>Cuidá tu auto con la mejor calidad en protectores</h3>
      </div>
    </section>
    <div class="mos-btn-lg" @click="scrollTo('productos')">
      <span>Nuestros Productos</span>
      <img src="/static/icons/arrow-down.svg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: ['isdark'],
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style scoped lang="scss">
#hero-home {
  min-height: 100vh;
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding-top: 12rem;
  padding-bottom: 8rem;
  position: relative;

  @media (max-width: 767px) {
    padding-top: 7rem;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
    padding-top: 7rem;
  }
  @media (min-width: 1400px) {
    padding-top: 12rem;
  }

  .main-box {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-rows: auto;
    width: 28rem;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    text-align: center;
    height: fit-content;

    @media (max-width: 767px) {
      width: 18rem;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }

    .com-brand {
      img {
        width: 11rem;
        height: auto;

        @media (max-width: 767px) {
          width: 9rem;
        }
        @media (min-width: 768px) {
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1200px) {
        }
      }
    }

    .com-name {
      img {
        width: 18rem;
        height: auto;

        @media (max-width: 767px) {
          width: 13rem;
        }
        @media (min-width: 768px) {
        }
        @media (min-width: 992px) {
        }
        @media (min-width: 1200px) {
        }
      }
    }
  }

  .mos-btn-lg {
    position: absolute;
    bottom: 50px;

    @media (max-width: 767px) {
      bottom: 30px;
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
  }
}
</style>
