<template>
  <div id="gallery-filters">
    <div class="wrapper">
      <article
        @click="selectCategory(null)"
        :class="categorySelected == null ? 'active-tag' : ''"
      >
        Todos
      </article>
      <article
        v-for="item in items"
        :key="item.id"
        :class="categorySelected == item.id ? 'active-tag' : ''"
        @click="selectCategory(item.id)"
      >
        {{ item.name }}
      </article>
    </div>
  </div>
</template>
<script>
import Categorias from '@/data/listings/categorias.json';

export default {
  data() {
    return {
      items: Categorias.data,
      categorySelected: null,
    };
  },
  methods: {
    selectCategory(id) {
      this.categorySelected = id;
      this.$emit('changeCategory', id);
    },
  },
};
</script>
<style scoped lang="scss">
#gallery-filters {
  margin-bottom: 2em;
  .wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    article {
      padding: 0.35em 1.5em;
      text-align: center;
      border-radius: 20px 40px;
      margin: 0 1em 1em 0;
      font-size: 13.5px;
      cursor: pointer;
      background-color: var(--color__card);
      color: var(--color__tertiary);
      transition: 0.2s all ease-in-out;

      &.active-tag {
        background-color: var(--color__primary);
        color: $tagcolor;
      }

      @media (max-width: 767px) {
        margin: 0 0.5em 0.5em 0;
        font-size: 13px;
      }
      @media (min-width: 768px) {
      }
      @media (min-width: 992px) {
      }
      @media (min-width: 1200px) {
      }
    }
  }
}
</style>
