<template>
  <router-view
    @switchtheme="switchtheme"
    :darkmode="darkmode"
    :key="$route.fullPath"
  />
</template>

<script>
export default {
  data() {
    return {
      darkmode: true,
    };
  },
  methods: {
    switchtheme(color) {
      if (color == true) {
        this.darkmode = true;
      } else {
        this.darkmode = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
